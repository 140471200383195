@use '@angular/material' as mat;
@include mat.core();

/* ======== Angular material custom DB Bahnpark themes ======== */  

// define a real custom palette (using http://mcg.mbitson.com)
$mat-dbbp-blue: (
    50 : #e0e8ee,
    100 : #b3c6d4,
    200 : #80a0b8,
    300 : #4d7a9c,
    400 : #265e86,
    500 : #004171,
    600 : #003b69,
    700 : #00325e,
    800 : #002a54,
    900 : #001c42,
    A100 : #77a3ff,
    A200 : #4480ff,
    A400 : #115dff,
    A700 : #004ff6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$mat-dbbp-red: (
    50 : #fde3e3,
    100 : #fbb9b9,
    200 : #f88a8a,
    300 : #f55b5b,
    400 : #f23737,
    500 : #f01414,
    600 : #ee1212,
    700 : #ec0e0e,
    800 : #e90b0b,
    900 : #e50606,
    A100 : #ffffff,
    A200 : #ffdada,
    A400 : #ffa7a7,
    A700 : #ff8d8d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$dbbp-custom-primary: mat.define-palette($mat-dbbp-blue);
$dbbp-custom-accent: mat.define-palette($mat-dbbp-blue); // $mat-dbbp-red, 100, 500, A100
$dbbp-custom-warn: mat.define-palette($mat-dbbp-red);

$dbbp-custom-theme: mat.define-light-theme($dbbp-custom-primary, $dbbp-custom-accent, $dbbp-custom-warn);

@include mat.all-component-themes($dbbp-custom-theme);