/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

$db-blue: #004171;
$db-red: #f01414;
$db-badge: #ffe10b;
$mat-badge-size: 30px;

html,
body {
  height: fit-content;
}

body {
  background-color: #f1f1f1;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-medium .mat-badge-content {
    width: $mat-badge-size;
    height: $mat-badge-size;
    line-height: $mat-badge-size;
}

.mat-badge-accent .mat-badge-content {
    background: $db-badge;
    color: black;
    font-weight: 400;
    font-family: monospace;
}

.preview-dialog-container .mat-dialog-container {
  padding: 0;
  overflow: hidden;
  width: 90vw;
  height: 90vh;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px !important;
  margin-left: -10px !important;
  border-radius: 50%;
  border: 2px solid #f1f1f1;
  border-top-color: $db-blue;
  animation: spinner 0.8s linear infinite;
  display: block;
}
